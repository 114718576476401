<template>
  <div v-if="item" class="ancestry-navigation d-flex-center">
    <div v-for="(ancestor, i) in ancestry" :key="ancestor.id" class="d-flex-center">
      <span v-if="i > 0" class="mr-3 text-secondary d-flex-center">
        <fluency-icon :type="icons.child" class="icon-sm"></fluency-icon>
      </span>
      <t-button variant="text"
                severity="secondary"
                :id="`ancestry-nav-${ancestor.type}`"
                :class="[classes.navItems, { 'active': item.planType === ancestor.type }]" @click="followCrumb(ancestor)">
        <fluency-icon :type="getIcon(ancestor.type)"
                      size="lg"
                      :class="[classes.navItemIcons, `${ancestor.type}-fg`]" />
        <div>{{ $filters.convertFromCamelCase(ancestor.type)}}</div>
      </t-button>
    </div>
  </div>
</template>

<script>
const extensionTypes = ['siteLink', 'structuredSnippet', 'callout', 'callExtension', 'priceExtension', 'imageExtension', 'locationExtension']
export default {
  name: 'ancestry-navigation',
  props: {
    plainTooltips: {
      type: Boolean,
      default: false
    },
    defaultItem: {
      type: String,
      default: 'activeItem'
    }
  },
  data () {
    return {
      icons: {
        child: 'chevronRight'
      },
      classes: {
        navItems: 'd-flex align-items-center',
        navItemsChevron: 'text-extra-muted',
        navItemIcons: '',
        navLink: 'px-0 py-0'
      },
      ancestry: [],
      hierarchy: ['account', 'budget', 'campaign', 'adGroup', 'creative', 'keyword', 'criterion', 'form', ...extensionTypes]
    }
  },
  computed: {
    item () {
      return this.$store.getters[this.defaultItem]
    }
  },
  watch: {
    item: {
      immediate: true,
      handler (val, old) {
        if (!old) {
          this.createAncestry(val)
          return
        }
        if (val) {
          const idType = `${val.planType}PlanId`
          if (!this.ancestry.some(a => a.id === val[idType])) {
            this.createAncestry(val)
          }
        }
      }
    }
  },
  methods: {
    async followCrumb ({ type, id }) {
      let planType = type
      if (type === 'creative') planType = 'ad'
      if (type === 'adGroup') planType = 'adgroup'
      this.$router.push({ query: { ...this.$route.query, settingsItemId: id, settingsItemType: planType } })
    },
    createAncestry (item) {
      if (!item) return
      this.ancestry = this.hierarchy.map(h => {
        const id = item[`${h}PlanId`]
        if (!id) return null
        return {
          type: h,
          id
        }
      }).filter(a => !!a)
    },
    getIcon (planType) {
      if (extensionTypes.includes(planType)) return 'extension'
      return planType
    }
  }
}
</script>

<style lang="scss">
.ancestry-navigation {
  //.account-bg { background-color: $color-key-account; }
  //.campaign-bg { background-color: $color-key-campaign; }
  //.adGroup-bg { background-color: $color-key-adgroup; }
  //.budget-bg { background-color: $color-key-budget; }
  //.creative-bg { background-color: $color-key-creative; }
  //.keyword-bg { background-color: $color-key-keywords; }
  //.criterion-bg { background-color: $color-key-criterion; }
  //.form-bg { background-color: $color-key-form; }
  .account-fg { color: var(--p-surface-500); }
  .campaign-fg { color: var(--p-surface-500); }
  .adGroup-fg { color: var(--p-surface-500); }
  .budget-fg { color: var(--p-surface-500); }
  .creative-fg { color: var(--p-surface-500); }
  .keyword-fg { color: var(--p-surface-500); }
  .criterion-fg { color: var(--p-surface-500); }
  .form-fg { color: var(--p-surface-500);}
  .siteLink-bg,
  .structuredSnippet-bg,
  .callout-bg,
  .callExtension-bg,
  .priceExtension-bg,
  .imageExtension-bg,
  .locationExtension-fg { color: $color-key-extensions }
}

</style>
